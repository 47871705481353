import { useEffect, useState } from "react";

import api from "@/services/api";
import { Link, useNavigate } from "react-router-dom";
import useStore from "@/services/store";
import Loader from "@/components/Loader";
import toast from "react-hot-toast";
import { HiOutlineTrash } from "react-icons/hi";
import ConfirmModal from "@/components/ConfirmModal";

import Logos from "@/assets/img/logos.png";
import Docs from "@/assets/img/docs.png";
import { useTranslation } from "react-i18next";

const BUYER_PRODUCTS = {
  RELATION: "product.buyer.realtion.title",
};

const MyService = () => {
  return (
    <div className="p-8 space-y-8">
      <div className="flex flex-col gap-16">
        <div className="flex flex-col gap-3">
          <h2 className="text-4xl text-primary font-bold mx-4">Mes services</h2>
          <div className="grid grid-cols-5">
            <div className="col-span-3 space-y-16 border-t-2 mt-2 pt-4">
              <Companies />
              <Investors />
            </div>
            <div className="col-span-2 flex flex-col items-center space-x-12">
              <img src={Docs} alt="empty" className="w-72" />
              <p className="font-medium text-center">+4000 entreprises accompagnées depuis 2017</p>
              <img src={Logos} alt="empty" className="w-full blur-sm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Companies = () => {
  const [companies, setCompanies] = useState([]);

  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const { user } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const res = await api.post("/company/search", { user_id: user._id, product: true });
      if (res.ok) setCompanies(res.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors de la récupération de vos entreprises");
    }
  };

  const handleClickDelete = (item, e) => {
    e.preventDefault();
    setSelectedItem(item);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      const res = await api.delete(`/company/${selectedItem._id}`);
      if (res.ok) {
        toast.success("Votre produit a bien été supprimée");
        navigate(0);
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };

  if (loading) return <Loader />;

  if (companies.length === 0)
    return (
      <div className="flex flex-col justify-center items-center space-y-2">
        <p className="text-lg text-gray-500 font-bold mx-4 w-full">Vous n'avez pas encore de services d'entreprises actifs.</p>
        <Link to="/valuation/new" className="primary-button">
          Créer une valorisation
        </Link>
        <Link to="/levee/new" className="primary-button">
          Créer une Levée de fonds
        </Link>
      </div>
    );

  return (
    <div>
      <ConfirmModal
        show={showDeleteModal}
        title="Supprimer cette entreprise?"
        message={`Êtes-vous sûr de vouloir supprimer ${selectedItem.company_name}?`}
        onConfirm={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
        confirmClassname="red-button"
      />
      <h3 className="text-xl text-gray-700 font-bold mx-4">Entreprises</h3>
      <div className="flex justify-between items-center mt-3 px-8">
        <h3 className="w-10" />
        <h3 className="flex-1 text-sm font-medium ml-5">Entreprise</h3>
        <h3 className="w-1/6 text-sm font-medium">Date de création</h3>
        <h3 className="w-1/3 text-sm font-medium">Services</h3>
        <div className="p-4"></div>
      </div>
      <div className="flex flex-col gap-3">
        {companies.map((item, index) => (
          <div key={index} className="border border-secondary hover:border-black transition-all rounded-lg flex justify-between items-center py-2 px-4 shadow-sm">
            <h3 className="w-10" />
            <div className="flex-1 items-center flex">
              <h1 className="text-sm text-primary font-semibold ml-5">{item.company_name}</h1>
            </div>
            <div className="w-1/6">{new Date(item.created_at).toLocaleDateString("fr-FR")}</div>
            <div className="w-1/3 flex items-center flex-wrap gap-4">
              {item.valuation && (
                <Link
                  to={`/valuation/${item.valuation_type}/${item._id}`}
                  className="text-green-500 border border-green-500 bg-green-100 px-1 py-1 rounded text-xs text-center font-medium hover:-translate-y-0.5 hover:shadow duration-100 transition-all"
                >
                  {item.valuation_plan === "NOTATION" ? "Notaion" : "Valorisation"}
                </Link>
              )}
              {item.levee && (
                <Link
                  to={`/levee/${item._id}`}
                  className="text-blue-500 border border-blue-500 bg-blue-100 px-1 py-1 rounded text-xs text-center font-medium hover:-translate-y-0.5 hover:shadow duration-100 transition-all"
                >
                  Levée de fonds
                </Link>
              )}
              {item.cession && (
                <Link
                  to={`/cession/${item._id}`}
                  className="text-purple-700 border border-purple-700 bg-purple-100 px-1 py-1 rounded text-xs text-center font-medium hover:-translate-y-0.5 hover:shadow duration-100 transition-all"
                >
                  Cession
                </Link>
              )}
              {item.valuation_game && (
                <Link
                  to={`/valuation-game-result/${item.company_type}/${item._id}`}
                  className="text-rose-700 border border-rose-800 bg-rose-100 px-1 py-1 rounded text-xs text-center font-medium hover:-translate-y-0.5 hover:shadow duration-100 transition-all"
                >
                  Jeu de valorisation
                </Link>
              )}
            </div>
            <button onClick={(e) => handleClickDelete(item, e)} className="hover:bg-red-400 rounded h-full p-2">
              <HiOutlineTrash />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const Investors = () => {
  const [investors, setInvestors] = useState([]);

  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const { user } = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    fetchInvestors();
  }, []);

  const fetchInvestors = async () => {
    try {
      setLoading(true);
      const res = await api.post("/investor/search", { user_id: user._id, buyer: true });
      if (res.ok) setInvestors(res.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors de la récupération de vos investissements");
    }
  };

  const handleClickDelete = (item, e) => {
    e.preventDefault();
    setSelectedItem(item);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      const res = await api.delete(`/investor/${selectedItem._id}`);
      if (res.ok) {
        toast.success("Votre produit a bien été supprimée");
        navigate(0);
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };

  if (loading) return <Loader />;

  if (investors.length === 0)
    return (
      <div className="flex flex-col justify-center items-center space-y-2">
        <p className="text-lg text-gray-500 font-bold mx-4 w-full">Vous n'avez pas encore de services d'investissements actifs.</p>
        <Link to="/buyer-service/new" className="primary-button">
          Créer une Reprise
        </Link>
      </div>
    );

  return (
    <div>
      <ConfirmModal
        show={showDeleteModal}
        title="Supprimer cet investissement?"
        onConfirm={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
        confirmClassname="red-button"
      />
      <h3 className="text-xl text-gray-700 font-bold mx-4">Investissements</h3>
      <div className="flex justify-between items-center mt-3 px-8">
        <h3 className="w-10" />
        <h3 className="flex-1 text-sm font-medium">Produit</h3>
        <h3 className="w-1/6 text-sm font-medium">Date de création</h3>
        <h3 className="w-1/3 text-sm font-medium">Services</h3>
        <div className="p-4"></div>
      </div>
      <div className="flex flex-col gap-3">
        {investors.map((item, index) => (
          <div key={index} className="border border-secondary hover:border-black transition-all rounded-lg flex justify-between items-center py-2 px-4 shadow-sm">
            <h3 className="w-10" />
            <div className="flex-1 items-center flex">
              <h1 className="text-sm text-primary font-semibold ml-5">{t(BUYER_PRODUCTS[item.buyer_plan])}</h1>
            </div>
            <div className="w-1/6">{new Date(item.created_at).toLocaleDateString("fr-FR")}</div>
            <div className="w-1/3 flex items-center flex-wrap gap-4">
              {item.buyer && (
                <Link
                  to={`/buyer-service/${item._id}`}
                  className="text-green-500 border border-green-500 bg-green-100 px-1 py-1 rounded text-xs text-center font-medium hover:-translate-y-0.5 hover:shadow duration-100 transition-all"
                >
                  Reprise
                </Link>
              )}
            </div>
            <button onClick={(e) => handleClickDelete(item, e)} className="hover:bg-red-400 rounded h-full p-2">
              <HiOutlineTrash />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyService;
