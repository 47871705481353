import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";

import UploadFileSVG from "@/assets/svg/upload-file.svg";
import api from "@/services/api.js";
import { DocumentIcon } from "@heroicons/react/24/solid";
import Loader from "@/components/Loader";

const Documents = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.post("/document/search", { company_id: id });
        if (!res.ok) throw res;
        setDocuments(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue lors de la récupération des documents");
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleNext = async () => {
    try {
      const res = await api.put(`/company/${id}`, { valuation_step: "INTERVIEW" });
      if (!res.ok) throw res;
      toast.success("Formulaire validé avec succès");
      navigate(`../interview`);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue lors de la validation du formulaire");
    }
  };

  const handleFileUpload = async (e) => {
    setLoading(true);
    try {
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("files", e.target.files[i]);
      }
      const res = await api.postFormData(`/document?company_id=${id}`, formData);
      if (!res.ok) throw res;
      setDocuments(res.data);

      navigate(0);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <div className="w-full space-y-8 px-40">
      <div className="grid grid-cols-2 gap-6 mb-36">
        <div className="col-span-2 flex flex-col gap-6 px-8">
          <h1 className="font-semibold text-xl">{t("document.title")}</h1>
          <p className="text-gray-500 text-sm">{t("document.text_1")}</p>
          <p className="text-gray-500 text-sm">{t("document.text_2")}</p>

          <div className="w-full">
            {documents.length > 0 && (
              <>
                <div className="flex justify-between items-center mb-5">
                  <h3 className="flex-1 text-sm font-semibold">{t("document.add.title")}</h3>
                  <h3 className="w-1/6 text-sm font-semibold text-right">{t("document.add.date")}</h3>
                  <h3 className="w-1/6 text-sm font-semibold text-right">{t("document.add.see")}</h3>
                  <h3 className="w-16 text-sm font-semibold text-right" />
                </div>
                <div className="divide-y divide-secondary">
                  {documents.map((item, index) => (
                    <DocRow key={index} item={item} index={index} setDocuments={setDocuments} />
                  ))}
                </div>
              </>
            )}
          </div>

          <div className="flex justify-center w-full">
            <label
              htmlFor="documents"
              className="bg-white mt-4 p-4 rounded-xl w-full h-full hover:bg-gray-50 transition-all cursor-pointer flex flex-col items-center justify-center border border-secondary border-dashed"
            >
              {loading ? (
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
                  <p className="text-primary">{t("document.add.loading")}</p>
                </div>
              ) : (
                <img src={UploadFileSVG} alt="upload" className="w-8 h-8" />
              )}
              <p className="mt-4">{t("document.add")}</p>
            </label>
            <input id="documents" type="file" className="hidden" disabled={loading} multiple onChange={handleFileUpload} />
          </div>
        </div>
      </div>

      <div className="flex justify-end w-full">
        <button type="button" className="primary-button w-48" onClick={handleNext}>
          {t("next")}
        </button>
      </div>
    </div>
  );
};

const DocRow = ({ item, index, setDocuments }) => {
  const [file_name, setFileName] = useState(item.file_name);

  useEffect(() => {
    setFileName(item.file_name);
  }, [item]);

  useEffect(() => {
    if (file_name === item.file_name) return;

    const timeout = setTimeout(async () => {
      const res = await api.put(`/document/${item.id}`, { file_name });
      if (res.ok) {
        setDocuments((prev) => {
          const newValue = [...prev];
          newValue[index].file_name = file_name;
          return newValue;
        });
        toast.success("Nom du fichier mis à jour");
      }
    }, 400);
    return () => clearTimeout(timeout);
  }, [file_name]);

  const handleDeleteFile = async () => {
    const confirm = window.confirm("Voulez-vous vraiment supprimer ce fichier ?");
    if (!confirm) return;
    try {
      const res = await api.delete(`/document/${item._id}`);
      if (res.ok) setDocuments((prev) => prev.filter((doc) => doc._id !== item._id));
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div className="flex justify-between items-center py-4">
      <div className="flex-1 items-center flex">
        <DocumentIcon className="w-6 h-6 text-primary mr-4" />
        <input type="text" value={file_name} className="input" onChange={(e) => setFileName(e.target.value)} />
      </div>
      <div className="w-1/6 text-right">{new Date(item.created_at).toLocaleDateString("fr-FR")}</div>
      <div className="w-1/6 flex justify-end">
        <a href={item.file_url} target="_blank" rel="noreferrer">
          <EyeIcon className="w-6 h-6 text-primary" />
        </a>
      </div>
      <div className="w-16 flex justify-end">
        <button type="button" className="transparent-button" onClick={() => handleDeleteFile(item)}>
          <TrashIcon className="w-6 h-6 text-primary cursor-pointer" />
        </button>
      </div>
    </div>
  );
};

export default Documents;
