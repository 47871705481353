import { Link } from "react-router-dom";

import AppLogo from "@/assets/img/logo_text_horizontal.png";
import EstimeoLogo from "@/assets/img/estimeo_logo_horizontal.png";

const Company = ({ company, setCompany }) => {
  const isDataValid = () => company.company_name && company.valuation_game_maturity && company.valuation_game_reason;

  return (
    <div className="flex flex-col px-8 py-8 w-[42rem] h-full justify-center bg-white">
      <div className="flex items-center mb-2">
        <div className="w-24 mb-4">
          <img className="w-full drop-shadow-md" src={AppLogo} alt="logo" />
        </div>
        <div className="text-5xl text-gray-700 font-medium mb-3 ml-1 mr-2">&times;</div>
        <div className="w-32 mb-4">
          <img className="w-full drop-shadow-md" src={EstimeoLogo} alt="logo" />
        </div>
      </div>
      <div className="flex flex-col items-start space-y-8">
        <h1 className="text-xl text-primary font-semibold">Informations générales de la startup</h1>
        <div className="space-y-4 w-full">
          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Nom de l'entreprise</p>
            <input
              type="text"
              name="name"
              placeholder="Saisissez le nom de l'entreprise"
              value={company.company_name || ""}
              onChange={(e) => setCompany({ ...company, company_name: e.target.value })}
              className="input shadow"
            />
          </div>

          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Quelle est la maturité de votre startup ?</p>
            <div className="flex gap-2 flex-wrap">
              <button
                className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                ${company.valuation_game_maturity === "Pré-revenu" ? "bg-primary text-white" : "border-primary"}`}
                onClick={() => setCompany({ ...company, valuation_game_maturity: "Pré-revenu" })}
              >
                <p className="text-sm text-center font-semibold">Pré-revenu</p>
              </button>
              <button
                className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                ${company.valuation_game_maturity === "Post-revenu" ? "bg-primary text-white" : "border-primary"}`}
                onClick={() => setCompany({ ...company, valuation_game_maturity: "Post-revenu" })}
              >
                <p className="text-sm text-center font-semibold">Post-revenu</p>
              </button>
            </div>
          </div>

          <div className="space-y-2">
            <p className="text-gray-600 font-medium">Raison de l'évaluation</p>
            <div className="flex gap-2 flex-wrap">
              <button
                className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                ${company.valuation_game_reason === "Lever des fonds" ? "bg-primary text-white" : "border-primary"}`}
                onClick={() => setCompany({ ...company, valuation_game_reason: "Lever des fonds" })}
              >
                <p className="text-sm text-center font-semibold">Lever des fonds</p>
              </button>
              <button
                className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                ${company.valuation_game_reason === "Vendre mon entreprise" ? "bg-primary text-white" : "border-primary"}`}
                onClick={() => setCompany({ ...company, valuation_game_reason: "Vendre mon entreprise" })}
              >
                <p className="text-sm text-center font-semibold">Vendre mon entreprise</p>
              </button>
              <button
                className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                ${company.valuation_game_reason === "Acheter une entreprise" ? "bg-primary text-white" : "border-primary"}`}
                onClick={() => setCompany({ ...company, valuation_game_reason: "Acheter une entreprise" })}
              >
                <p className="text-sm text-center font-semibold">Acheter une entreprise</p>
              </button>
              <button
                className={`border rounded-lg py-2 px-4 cursor-pointer text-gray-800 whitespace-nowrap
                ${company.valuation_game_reason === "Autre" ? "bg-primary text-white" : "border-primary"}`}
                onClick={() => setCompany({ ...company, valuation_game_reason: "Autre" })}
              >
                <p className="text-sm text-center font-semibold">Autre</p>
              </button>
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <Link to="/valuation-game/type" className="primary-button px-8">
            Précédent
          </Link>

          {isDataValid() ? (
            <Link to="/valuation-game/start-up/values" className="primary-button px-8">
              Suivant
            </Link>
          ) : (
            <button disabled className="primary-button px-8">
              Suivant
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Company;
